import React from "react";
import styled from "styled-components";
import "../../css/typographyc.css";

const StyledTrust = styled.div`
  background-color: var(--second-dark);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 72px 16px;
  h1 {
    font-family: "Russo_one";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
  }
  h2 {
    font-family: "Noto_sans_regular";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    margin-top: 48px;
    max-width: 80%;
    :last-child{
      margin-top: 10px;
    }
  }
  @media (min-width: 800px) {
    h1{
        font-size: 36px;
    }
    h2{
        font-size: 28px;
        margin-top: 62px;
    }
    .container_cards{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
    }
  }
`;
const StyledCardTrust = styled.div`
  background-color: var(--dark-gray-back);
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15);
  width: 328px;
  height: 192px;
  border-radius: 8px;
  padding: 25px;
  margin-top: 20px;
  p {
    font-family: "Noto_sans_regular";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 28px;
  }
  @media (min-width: 800px) {
    margin: 80px 20px 0;
  }
  @media (min-width: 1400px) {
    margin-left: 20px;
    margin-top: 80px;
  }
`;
export default function TrustUs() {
  return (
    <StyledTrust id="why-trust-scroll">
      <h1>Why trust us?</h1>
      <div className="container_cards">
        {cardsArray.map((e, idx) => {
          return (
            <StyledCardTrust>
              <img src={e.image} alt="Datastream" />
              <p>{e.textDescription}</p>
            </StyledCardTrust>
          );
        })}
      </div>
      {/* <Button textButton="Our portfolio" link={"/contact"}/> */}
      <h2>Ready to promote your innovative solutions?</h2>
      <h2>In the second largest IT Market in Latin America</h2>
    </StyledTrust>
  );
}

const cardsArray = [
  {
    image: require("../../Assets/icon_envio.svg").default,
    textDescription:
      "We know the Latin American business and distribution model in depth and have a network of strategic contacts.",
  },
  {
    image: require("../../Assets/icon_certificado.svg").default,
    textDescription:
      "Our engineers are certified in all the solutions we market and will help position your brand in the region.",
  },
  {
    image: require("../../Assets/icon_ubicacion.svg").default,
    textDescription:
      "Our partners are already providers for some of the largest accounts in Mexico, for the public and private sectors.",
  },
];
