import React from "react";
import TrustUs from "../components/homepage/TrustUs";
import Services from "../components/homepage/Services";
import Layout from "../components/layout";

export default function whytrustus() {
  return (
    <div>
      <Layout>
        <TrustUs />
        <Services />
      </Layout>
    </div>
  );
}
